import { IFile } from '@shared/interfaces/file';
import { IPerson, Person } from '@shared/models/person';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { Company, ICompany } from '@shared/models/company';
import { Berth } from '@shared/models/berth';
import { ProductSubscription, ProductSubscriptionDto } from '@shared/models/product-subscription';
import { tryParseDate } from '@shared/functions/try-parse-date';
import {
    IWinterStorageBoatAssignment,
    WinterStorageBoatAssignment
} from '@shared/models/winter-storage-boat-assignment';
import { WinterStorage } from '@shared/models/winter-storage';
import { ElectricMeterAssignment } from '@shared/models/electric-meter-assignment';
import { BcmDocument, BcmDocumentDto } from '@shared/models/bcm-document';
import { IReadingRaw, Reading } from '@shared/models/electric-meter-reading';
import { BcmVoucher, BcmVoucherDto } from '@shared/models/bcm-voucher';
import { BcmAbsence, BcmAbsenceDto } from '@shared/models/bcm-absence';

export interface BoatEngine {
    engineNumber: string;
    engineType: string;
    engineHP: number;
    engineKW: number;
}

export interface IBoat {
    id: number;
    name: string;
    slug: string;
    type: string;
    manufacturer: string;
    model: string;
    length: number;
    lengthOverall: number;
    width: number;
    weight: number;
    sqm: number;
    depth: number;
    HIN: string;
    hullShape: string;
    keelShape: string;
    licensePlate: string;
    constructionYear: string; // Maybe Date
    launchingYear: number; // Maybe Date
    ownershipSince: string;
    hasGasSystem: boolean;
    isGasSystemInactive: boolean;
    portOfRegistry: string;
    nauticalPosition: string;
    note?: string;

    image: IFile;

    insertedOn: string | Date;
    lastUpdatedOn: string | Date;
    lastUpdatedBy: number;
    insertedBy: number;

    owner?: IPerson;
    ownerCompany?: ICompany;
    subscriptions: ProductSubscriptionDto[];
    documentsOrigin: BcmDocumentDto[];
    documents: BcmDocumentDto[];

    electricMeterAssignments: any[];
    electricMeterReadings: IReadingRaw[];

    berthAssignments: IBerthBoatAssignment[];
    berthAssignment: IBerthBoatAssignment;
    winterStorageAssignments: IWinterStorageBoatAssignment[];
    winterStorageAssignment: IWinterStorageBoatAssignment;

    maxCarryingCapacity?: number;

    numberOfEngines: number;
    engines: BoatEngine[];
    fuelType: string;
    driveType: string;
    mastLength: number;

    nrz: number;
    brz: number;
    tdw: number;
    imoNr: string;
    callSign: string;
    homePort: string;

    vouchers: BcmVoucherDto[];
    absences: BcmAbsenceDto[];
}

export class Boat {
    id: number;
    name: string;
    slug: string;
    type: string;
    manufacturer: string;
    model: string;
    length: number;
    lengthOverall: number;
    width: number;
    weight: number;
    sqm: number;
    depth: number;
    licensePlate: string;
    constructionYear: number;
    launchingYear: number;
    ownershipSince: string | Date;
    hasGasSystem: boolean;
    isGasSystemInactive: boolean;
    hasGasSystemWarning: boolean;
    portOfRegistry: string;
    nauticalPosition: string;
    image: IFile;
    HIN: string;
    hullShape: string;
    keelShape: string;
    note?: string;
    invoiceState?: string;

    insertedOn: Date;
    insertedBy: number;

    lastUpdatedOn: Date;
    lastUpdatedBy: number;

    owner?: Person;
    ownerCompany?: Company;
    subscriptions: ProductSubscription[];

    documentsOrigin: BcmDocument[];
    documents: BcmDocument[];

    electricMeterAssignments: ElectricMeterAssignment[];
    electricMeterReadings: Reading[];

    berthAssignments: BerthBoatAssignment[];
    futureBerthAssignments: BerthBoatAssignment[];
    berthAssignment: BerthBoatAssignment;

    winterStorageAssignments: WinterStorageBoatAssignment[];
    winterStorageAssignment: WinterStorageBoatAssignment;

    berth: Berth;
    winterStorage: WinterStorage;

    maxCarryingCapacity?: number; // max. Tragkraft (t)

    numberOfEngines: number;
    engines: BoatEngine[];
    fuelType: string;
    driveType: string;
    mastLength: number;

    // create only
    ownerId: number;
    ownerCompanyId: number;

    // Front-End only
    dimensions: [string | number, string | number, string | number];
    dimensionsString: string;

    boatSpecsCommaSeparated: string;

    // helper
    isNewOwnerUntil?: Date;

    ownerName = '';

    nrz: number;
    brz: number;
    tdw: number;
    imoNr: string;
    callSign: string;
    homePort: string;

    vouchers: BcmVoucher[];
    absences: BcmAbsence[];

    public get activeAssignments(): BerthBoatAssignment[] {
        const today = new Date();
        return this.berthAssignments
            .filter(
                assignment => assignment.from <= today && (
                    assignment.to === null || assignment.to >= today
                )
            );
    }

    public get nextAssignments(): BerthBoatAssignment[] {
        const today = new Date();
        return this.berthAssignments
            .filter(
                assignment => assignment.from > today && (
                    assignment.to === null || assignment.to > today
                )
            );
    }

    public get previousAssignments(): BerthBoatAssignment[] {
        const today = new Date();
        return this.berthAssignments
            .filter(assignment => assignment.to < today && assignment.from < today);
    }

    public get activeElectricMeterAssignments(): ElectricMeterAssignment[] {
        const today = new Date();
        return this.electricMeterAssignments
            .filter(
                assignment => assignment.from <= today && (
                    assignment.to === null || assignment.to >= today
                )
            );
    }

    public get activeAndUpcomingAbsences(): BcmAbsence[] {
        const today = new Date();
        return this.absences
            .filter(assignment => assignment.until >= today);
    }

    public get fullName() {
        return this.toString();
    }

    constructor(boat: IBoat = {} as IBoat) {
        this.id = boat.id || null;
        this.name = boat.name || '';
        this.slug = boat.slug || '';
        this.type = boat.type || '';
        this.manufacturer = boat.manufacturer || '';
        this.model = boat.model || '';
        this.length = boat.length || null;
        this.lengthOverall = boat.lengthOverall ?? boat.length ?? null;
        this.width = boat.width || null;
        this.weight = boat.weight || null;
        this.sqm = boat.sqm || null;
        this.depth = boat.depth || null;
        this.licensePlate = boat.licensePlate || '';
        this.constructionYear = parseInt(boat.constructionYear, 10) || null;
        this.launchingYear = boat.launchingYear || null;
        this.ownershipSince = tryParseDate(boat.ownershipSince);
        this.hasGasSystem = boat.hasGasSystem;
        this.isGasSystemInactive = boat.isGasSystemInactive;
        this.image = (boat.image || {}) as IFile;
        this.HIN = boat.HIN || '';
        this.hullShape = boat.hullShape || '';
        this.keelShape = boat.keelShape || '';
        this.portOfRegistry = boat.portOfRegistry;
        this.nauticalPosition = boat.nauticalPosition;
        this.note = boat.note;

        this.documents = boat.documents?.map(document => new BcmDocument(document)) || [];

        this.checkGasSystemDocument();

        this.owner = boat.owner ? new Person(boat.owner, true) : null;
        this.ownerCompany = boat.ownerCompany ? new Company(boat.ownerCompany, true) : null;
        this.ownerName = [
            this.ownerCompany?.fullName,
            this.owner?.fullNameBackward
        ].filter(_ => _ != null).join(', ') || 'ZZ';

        this.subscriptions = (boat.subscriptions || []).map(subscription => new ProductSubscription(subscription));
        this.electricMeterAssignments = (boat.electricMeterAssignments || []).map(electricMeterAssignment => new ElectricMeterAssignment(electricMeterAssignment));
        this.electricMeterReadings = (boat.electricMeterReadings || []).map(electricMeterReading => new Reading(electricMeterReading));
        this.berthAssignments = (boat.berthAssignments || []).map(assignment => new BerthBoatAssignment(assignment));
        this.futureBerthAssignments = this.getFutureAssignments();
        this.berthAssignment = this.getActiveAssignment();

        this.winterStorageAssignments = (boat.winterStorageAssignments || []).map(assignment => new WinterStorageBoatAssignment(assignment));
        this.winterStorageAssignment = this.getActiveWinterStorageAssignment();

        // todo: is this.boat && this.winterStorage correct? What if there are multiple?
        this.berth = this.berthAssignment ? this.berthAssignment.berth : null;
        this.winterStorage = this.winterStorageAssignment ? this.winterStorageAssignment.winterStorage : null;

        this.insertedOn = tryParseDate(boat.insertedOn as string);
        this.lastUpdatedOn = tryParseDate(boat.lastUpdatedOn as string);

        this.maxCarryingCapacity = boat.maxCarryingCapacity;

        this.numberOfEngines = boat.numberOfEngines || null;
        this.engines = boat.engines || [];
        this.fuelType = boat.fuelType || null;
        this.driveType = boat.driveType || null;
        this.mastLength = boat.mastLength || null;

        this.dimensions = [boat.length || 0, boat.width || 0, boat.depth || 0];
        this.dimensionsString = this.dimensions?.length ?
            // @ts-ignore
            this.dimensions.filter(value => value > 0).join('/').replace(/\./g, ',')
            : '';

        this.boatSpecsCommaSeparated = [
            (boat.length ? `Länge: ${boat.length}m` : ''),
            (boat.width ? `Breite: ${boat.width}m` : ''),
            (boat.depth ? `Tiefgang: ${boat.depth}m` : ''),
            (boat.sqm ? `Fläche: ${boat.sqm}m²` : '')
        ].filter(_ => !!_).join(', ') || '';

        this.nrz = boat.nrz;
        this.brz = boat.brz;
        this.tdw = boat.tdw;
        this.imoNr = boat.imoNr;
        this.callSign = boat.callSign;
        this.homePort = boat.homePort;

        this.vouchers = (boat.vouchers || []).map(voucher => new BcmVoucher(voucher));
        this.absences = (boat.absences || []).map(absence => new BcmAbsence(absence));
    }

    checkGasSystemDocument(): void {
        const gasRevisionDocument = this.documents.find(doc => doc.documentCategory?.systemKey === 'GAP' && !doc.expired);
        this.hasGasSystemWarning = this.hasGasSystem && !gasRevisionDocument;
    }

    toString(): string {
        return [
            this.licensePlate || null,
            this.name || null
        ]
            .filter(item => item != null)
            .join(', ') || 'Ohne Identifikator';
    }

    fullTextSearch(): string {
        return [
            this.name,
            this.licensePlate,
            this.HIN,
            this.owner?.toString(),
            this.ownerCompany?.toString(),
            this.berthAssignment?.berth?.toString()
        ].join('');
    }

    valueOf(): number {
        return this.id;
    }

    public getActiveAssignment(): BerthBoatAssignment {
        const today = new Date();
        return this.berthAssignments
            .find(
                assignment => assignment.from <= today && (
                    assignment.to === null || assignment.to >= today
                )
            );
    }

    public getFutureAssignments(): BerthBoatAssignment[] {
        return this.berthAssignments.filter(assignment => assignment.from > new Date());
    }

    public getActiveWinterStorageAssignment(): WinterStorageBoatAssignment {
        const today = new Date();
        return this.winterStorageAssignments
            .find(
                assignment => assignment.to === null
                    || (assignment.to >= today && assignment.from <= today)
            );
    }

    public hasActiveAssignment(): boolean {
        return !!this.getActiveAssignment();
    }
}
