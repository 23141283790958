import { BcmPaymentType, IPerson, Person } from '@shared/models/person';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { EventEmitter } from '@angular/core';
import { Company, ICompany } from '@shared/models/company';
import { Tenant } from '@shared/models/tenant';
import { SafeUrl } from '@angular/platform-browser';
import { InvoiceTypes, ReceiptTypes } from '@shared/invoice-types';
import { isValid, parseISO } from '@core/date.facade';
import { naturalSort } from '@shared/functions/natural-sort';
import { roundNumber, RoundNumberFactor } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';
import { StaticGlobals } from '@modules/bcm/@shared/static-globals';
import { BcmInvoiceWdTemplate } from '@shared/models/bcm-invoice-wd-template';
import { getTaxRateValue } from '@core/functions/get-tax-rate-value';
import { FinancialRecordStatus } from '@shared/models/bcm-financial-record';
import { InvoicePayment, InvoicePaymentDto } from '@shared/models/invoice-payment';
import { getAddressString } from '@shared/functions/get-address-string';
import { v4 as uuidv4 } from 'uuid';

export interface InvoiceSentInformation {
    id: number;
    bcm_invoices_id: number;
    sentTo: string;
    information: any;
    insertedOn: Date;
    insertedBy: string;
}

export interface IInvoice {
    id?: number;
    isSepa?: boolean;
    person?: IPerson;
    company?: ICompany;
    positions: InvoicePositionDto[];
    totalDiscount: number;
    totalGrossPrice: number;
    totalNetPrice: number;
    totalTaxes: { [key: number]: number };
    totalTax: number;
    note: string;
    internalNote: string;
    date: string;
    dueDate: string;
    invoiceNumber: string;
    clerk: string;
    tenant: Tenant;
    payedOn: string;
    invoiceType: InvoiceTypes;
    cancellationInvoice: IInvoice;
    cancellationReason?: string;
    address: string;
    sentInformation: InvoiceSentInformation;
    paymentType: BcmPaymentType;
    pdfDataUrl: string;
    invoiceStatus: FinancialRecordStatus;
    searchHelpers: { [key: string]: string };
    wordTemplate?: BcmInvoiceWdTemplate;
    subject?: string;
    taxNumber?: string;
    yourSign?: string;
    payments?: InvoicePaymentDto[];
}

export class Invoice {
    private readonly _id?: number;
    private _isSepa: boolean;
    private _totalDiscount = 0;
    private _totalNetPrice = 0;
    private _totalTaxFreeProductsPrice = 0;
    private _totalGrossPrice = 0;
    private _totalTaxes: { [key: number]: number };
    private _totalTax = 0;
    private _person: Person;
    private _company: Company;
    private _recipientName: string;
    private _positions: InvoicePosition[] = [];
    private _positionsDirty: boolean;
    private _note: string;
    private _internalNote: string;
    private _date: Date;
    private _dueDate: Date;
    private readonly _invoiceNumber: string;
    private _clerk: string;
    private _paymentType: BcmPaymentType = BcmPaymentType.Invoice;
    private _tenant: Tenant;
    private readonly _pdfDataUrlUnsafe: string;
    private _pdfDataUrl: SafeUrl;
    private _payedOn: Date;
    private _invoiceType = InvoiceTypes.Invoice;
    private _cancellationInvoice: Invoice;
    private _address: string;
    private readonly _sentInformation: InvoiceSentInformation;
    private _wordTemplate?: BcmInvoiceWdTemplate;
    public invoiceStatus: FinancialRecordStatus;
    public searchHelpers: { [key: string]: string };
    public subject?: string;
    public taxNumber?: string;
    public yourSign?: string;
    public payments: InvoicePayment[] = [];
    public paidAmount?: number;
    public missingAmount?: number;
    public cancellationReason?: string;

    uuid: string;

    public totalGrossPriceChange = new EventEmitter<number>();

    public get id(): number {
        return this._id;
    }

    public get isSepa(): boolean {
        return this._isSepa;
    }

    public get totalDiscount(): number {
        return this._totalDiscount;
    }

    public get totalNetPrice(): number {
        return this._totalNetPrice;
    }

    public get totalTaxFreeProductsPrice(): number {
        return this._totalTaxFreeProductsPrice;
    }

    public get totalGrossPrice(): number {
        return this._totalGrossPrice;
    }

    public get totalTaxes(): { [key: number]: number } {
        return this._totalTaxes;
    }

    public get totalTaxesList(): Array<{ taxRate: number, value: number }> {
        return naturalSort(Object.keys(this._totalTaxes))
            .map((key) => ({taxRate: parseFloat(key), value: this._totalTaxes[key]}));
    }

    public get totalTaxesListFiltered(): Array<{ taxRate: number, value: number }> {
        return naturalSort(Object.keys(this._totalTaxes))
            .filter(key => key !== '0')
            .map((key) => ({taxRate: parseFloat(key), value: this._totalTaxes[key]}));
    }

    public get totalTax(): number {
        return this._totalTax;
    }

    public get person(): Person {
        return this._person;
    }

    public set person(person: Person) {
        this._person = person;
    }

    public get tenant(): Tenant {
        return this._tenant;
    }

    public set tenant(tenant: Tenant) {
        this._tenant = tenant;
    }

    public get company(): Company {
        return this._company;
    }

    public set company(company: Company) {
        this._company = company;
    }

    public get recipientName(): string {
        return this._recipientName;
    }

    public set recipientName(recipientName: string) {
        this._recipientName = recipientName;
    }

    public get positions(): InvoicePosition[] {
        return this._positions;
    }

    public get positionsDirty(): boolean {
        return this._positionsDirty;
    }

    public get note(): string {
        return this._note;
    }

    public set note(note: string) {
        this._note = note;
    }

    public get internalNote(): string {
        return this._internalNote;
    }

    public set internalNote(note: string) {
        this._internalNote = note;
    }

    public get date(): Date {
        return this._date;
    }

    public set date(date: Date) {
        this._date = date;
    }

    public get dueDate(): Date {
        return this._dueDate;
    }

    public set dueDate(dueDate: Date) {
        this._dueDate = dueDate;
    }

    public get invoiceNumber(): string {
        return this._invoiceNumber;
    }

    public get clerk(): string {
        return this._clerk;
    }

    public set clerk(clerk: string) {
        this._clerk = clerk;
    }

    public get paymentType(): BcmPaymentType {
        return this._paymentType;
    }

    public set paymentType(paymentType: BcmPaymentType) {
        this._paymentType = paymentType;
    }

    public get pdfDataUrl(): SafeUrl {
        return this._pdfDataUrl;
    }

    public set pdfDataUrl(pdfDataUrl: SafeUrl) {
        this._pdfDataUrl = pdfDataUrl;
    }

    public get pdfDataUrlUnsafe(): string {
        return this._pdfDataUrlUnsafe;
    }

    public set payedOn(payedOn: Date) {
        this._payedOn = payedOn;
    }

    public get payedOn(): Date {
        return this._payedOn;
    }

    public set invoiceType(invoiceType: InvoiceTypes) {
        this._invoiceType = invoiceType;
    }

    public get invoiceType(): InvoiceTypes {
        return this._invoiceType;
    }

    public set wordTemplate(wordTemplate: BcmInvoiceWdTemplate) {
        this._wordTemplate = wordTemplate;
    }

    public get wordTemplate(): BcmInvoiceWdTemplate {
        return this._wordTemplate;
    }

    public set cancellationInvoice(cancellationInvoice: Invoice) {
        this._cancellationInvoice = cancellationInvoice;
    }

    public get cancellationInvoice(): Invoice {
        return this._cancellationInvoice;
    }

    public set address(address: string) {
        if (this._person?.id != null) {
            this._isSepa = this._person.SEPAMandat;
        }

        if (this._company?.id != null) {
            this._isSepa = this._company.SEPAMandat;
        }

        this._address = address;
    }

    public get address(): string {
        return this._address;
    }

    public get addressView(): string {
        if (this._person?.id != null) {
            return getAddressString(this._person, '<br>');
        }

        if (this._company?.id != null) {
            return getAddressString(this._company, '<br>');
        }

        return this.address?.split('\n').join('<br>');
    }

    public get sentInformation(): InvoiceSentInformation {
        return this._sentInformation;
    }

    constructor(invoiceRaw: IInvoice = {} as IInvoice) {
        this._id = invoiceRaw.id;
        this._person = new Person(invoiceRaw.person || undefined);
        this._company = new Company(invoiceRaw.company || undefined);
        this._recipientName = invoiceRaw.address;
        this._address = invoiceRaw.address;
        this._tenant = new Tenant(undefined);
        this._positions = (invoiceRaw.positions || []).map(pos => new InvoicePosition(pos));
        this._note = invoiceRaw.note;
        this._internalNote = invoiceRaw.internalNote;
        this._sentInformation = invoiceRaw.sentInformation;
        this.subject = invoiceRaw.subject;
        this.taxNumber = invoiceRaw.taxNumber;
        this.yourSign = invoiceRaw.yourSign;

        const date = parseISO(invoiceRaw.date);
        const dueDate = parseISO(invoiceRaw.dueDate);
        const payedOn = parseISO(invoiceRaw.payedOn);

        this._date = isValid(date) ? date : new Date();

        if (isValid(dueDate)) {
            this._dueDate = dueDate;
        }

        this._invoiceNumber = invoiceRaw.invoiceNumber;
        this._clerk = invoiceRaw.clerk;
        this._paymentType = invoiceRaw.paymentType || BcmPaymentType.Invoice;
        this._pdfDataUrlUnsafe = invoiceRaw.pdfDataUrl;
        this._invoiceType = invoiceRaw.invoiceType || InvoiceTypes.Invoice;
        this._payedOn = isValid(payedOn) ? payedOn : null;
        this._cancellationInvoice = invoiceRaw.cancellationInvoice ? new Invoice(invoiceRaw.cancellationInvoice) : null;
        this.cancellationReason = invoiceRaw.cancellationReason || null;
        this.invoiceStatus = invoiceRaw.invoiceStatus || FinancialRecordStatus.Open;
        this.updatePrices();

        this._wordTemplate = invoiceRaw.wordTemplate ? new BcmInvoiceWdTemplate(invoiceRaw.wordTemplate) : null;

        this.payments = (invoiceRaw.payments || []).map(payment => new InvoicePayment(payment));
        this.updatePaymentValues();

        this.searchHelpers = {
            ...invoiceRaw.searchHelpers,
            name: this._person?.id
                ? this._person.toString(true)
                : this._company.toString(),
            invoiceNumber: this.invoiceNumber,
            cancelInvoiceNumber: this._cancellationInvoice?.invoiceNumber,
            price: this._totalGrossPrice.toString(),
        };

        this.uuid = uuidv4();
    }

    public removePositionsFromPreviousInvoiceRecipient(): void {
        this._positions = this._positions.filter(p => p.id == null);
        this._positionsDirty = true;
        this.updatePrices();
    }

    public addPosition(newPosition: InvoicePosition): void {
        this._positions.push(newPosition);
        this._positionsDirty = true;
        this.updatePrices();
    }

    public removePosition(positionToRemove: InvoicePosition, index: number): void {
        this._positions.splice(index, 1);
        this._positionsDirty = true;
        this.updatePrices();
    }

    public updatePrices(): void {
        const totalTaxes: { [key: number]: number } = {};
        let totalNetPrice = 0;
        let totalGrossPrice = 0;
        let totalTax = 0;
        let totalTaxFreeProductsPrice = 0;

        let currentTotalPrice;
        let currentTotalGrossPrice;
        let currentTotalNetPrice;
        let currentTotalTax;

        const signMultiplier: 1 | -1 = [
            InvoiceTypes.Invoice,
            InvoiceTypes.Crediting,
            ReceiptTypes.Receipt
        ].includes(this.invoiceType)
            ? 1
            : -1;

        this._positions.forEach(position => {

            const {quantity, totalPrice} = position;
            const taxRateValue = getTaxRateValue(position);

            if (totalPrice == null || quantity == null) {
                return;
            }

            currentTotalPrice = totalPrice * signMultiplier;

            if (StaticGlobals.netPrices) {
                currentTotalNetPrice = StaticGlobals.roundTo5Cent
                    ? roundNumber(currentTotalPrice, RoundNumberFactor.Swiss5CentimesRounding)
                    : currentTotalPrice;
                currentTotalTax = currentTotalNetPrice * (taxRateValue / 100);

                if (StaticGlobals.roundTo5Cent) {
                    currentTotalTax = roundNumber(currentTotalTax, RoundNumberFactor.Swiss5CentimesRounding);
                }

                currentTotalGrossPrice = currentTotalNetPrice + currentTotalTax;
            } else {
                currentTotalGrossPrice = StaticGlobals.roundTo5Cent
                    ? roundNumber(currentTotalPrice, RoundNumberFactor.Swiss5CentimesRounding)
                    : currentTotalPrice;
                currentTotalTax = currentTotalGrossPrice / (100 + taxRateValue) * taxRateValue;

                if (StaticGlobals.roundTo5Cent) {
                    currentTotalTax = roundNumber(currentTotalTax, RoundNumberFactor.Swiss5CentimesRounding);
                }

                currentTotalNetPrice = currentTotalGrossPrice - currentTotalTax;
            }

            totalTaxes[taxRateValue] = totalTaxes[taxRateValue] || 0;
            totalTaxes[taxRateValue] += currentTotalTax;

            totalGrossPrice += currentTotalGrossPrice;
            totalNetPrice += currentTotalNetPrice;
            totalTax += currentTotalTax;

            if (taxRateValue === 0) {
                totalTaxFreeProductsPrice += currentTotalNetPrice;
            }
        });

        this._totalNetPrice = roundNumber(totalNetPrice, RoundNumberFactor.TwoDecimals);
        this._totalGrossPrice = roundNumber(totalGrossPrice, RoundNumberFactor.TwoDecimals);
        this._totalTaxFreeProductsPrice = roundNumber(totalTaxFreeProductsPrice, RoundNumberFactor.TwoDecimals);
        this._totalTax = roundNumber(totalTax, RoundNumberFactor.TwoDecimals);

        this.totalGrossPriceChange.emit(this._totalGrossPrice);

        Object
            .keys(totalTaxes)
            .forEach((key) => totalTaxes[key] = roundNumber(totalTaxes[key], RoundNumberFactor.TwoDecimals));

        this._totalTaxes = totalTaxes;
    }

    public updatePaymentValues() {
        this.paidAmount = this.payments.reduce(
            (accumulator, currentPayment) => accumulator + currentPayment.amount,
            0,
        );
        this.missingAmount = this.totalGrossPrice - this.paidAmount;
    }
}
