import { Injectable } from '@angular/core';
import { BcmBooking } from '@shared/models/bcm-booking';
import { BookingDialogTenantRelationAssignment } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-tenant-relation-assignment';
import { BookingDialogGeneral } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-general';
import { BookingDialogTravelers } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-travelers';
import { BookingDialogElectricMeterAssignments } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-electric-meter-assignments';
import { BookingDialogDocuments } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-documents';
import { BookingDialogPositions } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-positions';
import { BookingDialogBerthAssignments } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-berth-assignments';
import { BookingDialogBoat } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-boat';
import { BookingDialogPerson } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-person';
import { BookingDialogCompany } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-company';
import { BookingDialogFinancialRecords } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-financial-records';
import { BookingDialogSubscriptions } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-subscriptions';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { BcmBookingsApiService } from '@bcmApiServices/bcm-bookings.api-service';
import { Observable } from 'rxjs';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';

@Injectable({providedIn: 'root'})
export class BookingDialogService {

    initialized = false;
    loading = false;
    unsavedChanges = false;

    readonlyView = false;

    booking: BcmBooking;

    general: BookingDialogGeneral;
    person: BookingDialogPerson;
    company: BookingDialogCompany;
    boat: BookingDialogBoat;
    berthAssignments: BookingDialogBerthAssignments;
    electricMeterAssignments: BookingDialogElectricMeterAssignments;
    travelers: BookingDialogTravelers;
    tenantRelationAssignment: BookingDialogTenantRelationAssignment;
    subscriptions: BookingDialogSubscriptions;
    positions: BookingDialogPositions;
    financialRecords: BookingDialogFinancialRecords;
    documents: BookingDialogDocuments;

    constructor(
        private _bookingsApiService: BcmBookingsApiService,
        private _productService: ProductService,
        private _bcmSettingsFacade: BcmSettingsFacade,
    ) {
    }

    async setBooking(booking: BcmBooking, setInitialized = false) {
        this.booking = booking;

        this.general.setBooking(booking);
        this.travelers.setBooking(booking);

        this.boat.setBooking(booking);
        this.person.setBooking(booking);
        this.company.setBooking(booking);
        this.subscriptions.setBooking(booking);
        this.positions.setBooking(booking);

        this.berthAssignments.setBooking(booking);
        this.electricMeterAssignments.setBooking(booking);
        await this.tenantRelationAssignment.setBooking(booking);

        this.financialRecords.setBooking(booking);
        this.documents.setBooking(booking);

        if (setInitialized) {
            this.initialized = true;
        }

    }

    reset() {
        this.initialized = false;
        this.unsavedChanges = false;

        this.booking = null;

        this.general = new BookingDialogGeneral(this);
        this.person = new BookingDialogPerson(this);
        this.company = new BookingDialogCompany(this);
        this.boat = new BookingDialogBoat(this);
        this.berthAssignments = new BookingDialogBerthAssignments(this);
        this.electricMeterAssignments = new BookingDialogElectricMeterAssignments(this);
        this.travelers = new BookingDialogTravelers(this);
        this.subscriptions = new BookingDialogSubscriptions(this, this._productService);
        this.positions = new BookingDialogPositions(this, this._bookingsApiService, this._productService, this._bcmSettingsFacade);
        this.tenantRelationAssignment = new BookingDialogTenantRelationAssignment(this);
        this.financialRecords = new BookingDialogFinancialRecords(this, this._bookingsApiService);
        this.documents = new BookingDialogDocuments(this, this._bookingsApiService);
    }

    canSave(): string[] {
        const errors = [];

        if (!this.person.value && !this.company.value) {
            errors.push('bookingDialogPersonCompanyError');
        }

        if (!this.boat.value) {
            errors.push('bookingDialogBoatError');
        }

        if (!this.berthAssignments.isFormValid() || this.berthAssignments.value?.length === 0) {
            errors.push('bookingDialogBerthAssignmentsError');
        }

        if (!this.travelers.isFormValid()) {
            errors.push('bookingDialogTravelersError');
        }

        if (this.tenantRelationAssignment.value?.captureTenantRelation && !this.tenantRelationAssignment.isFormValid()) {
            errors.push('bookingDialogTenantRelationAssignmentError');
        }

        return errors;
    }

    saveBooking(): Observable<BcmBooking> {

        const dto = this._createDto();

        if (this.booking.id) {
            return this._bookingsApiService.update(this.booking.id, dto);
        } else {
            return this._bookingsApiService.add(dto);
        }
    }

    private _createDto(): Partial<BcmBooking> {
        return {
            general: this.general.value,
            person: this.person.value,
            company: this.company.value,
            boat: this.boat.value,
            berthAssignments: this.berthAssignments.value,
            electricMeterAssignments: this.electricMeterAssignments.value,
            travelers: this.travelers.value,
            tenantRelationAssignment: this.tenantRelationAssignment.value,
            positions: this.positions.value,
            subscriptions: this.subscriptions.value,
            financialRecords: this.financialRecords.value,
            documents: this.documents.value,
        };
    }

    updateBookingDates() {
        this.booking.from = this.berthAssignments.getFromDate();
        this.booking.to = this.berthAssignments.getToDate();

        if (this.readonlyView) {
            return;
        }

        this.tenantRelationAssignment.updateDates();

        this.positions.updateDatesFromBerthAssignments();
    }

}
