import { Boat, IBoat } from '@shared/models/boat';
import { Berth, IBerth } from '@shared/models/berth';
import { TenantRelation, TenantRelationRaw } from '@shared/models/tenant-relation';
import { Season, SeasonRAW } from '@shared/models/seasons';
import { Company } from '@shared/models/company';
import { Person } from '@shared/models/person';
import { ITaxRate } from '@shared/models/tax-rate';
import { IWinterStorage, WinterStorage } from '@shared/models/winter-storage';
import { InvoicePosition } from '@shared/models/invoice-position';
import { Pier, PierRaw } from '@shared/models/pier';

export interface DynamicPriceRuleEvaluation {
    conditionEntity: string;
    conditionOperatorText: string;
    conditionOperatorSymbol: string;
    requiredValue: string;
    givenValue: string;
    isTrue: boolean;
}

export interface DynamicPriceRuleEvaluations {
    evaluations: DynamicPriceRuleEvaluation[];
    price: number;
}

export interface DynamicPriceCalculation {
    conditionEntity: string;
    conditionOperatorText: string;
    conditionOperatorSymbol: string;
    givenValue: string;
    priceAferCalculation: number;
    priceBeforeCalculation: number;
}

export interface DynamicPriceCalculations {
    calculations: DynamicPriceCalculation[];
    price: number;
}

export interface BcmDynamicPriceDto {
    id?: number;

    taxRate?: ITaxRate;

    boat?: IBoat;
    berth?: IBerth;
    tenantRelation?: TenantRelationRaw;
    season?: SeasonRAW;
    winterStorage?: IWinterStorage;
    pier?: PierRaw;

    days?: number;
    overnights?: number;
    travelers?: number;

    ruleIndex?: number;
    ruleName?: string;
    rulePrice?: number;

    ruleEvaluations?: DynamicPriceRuleEvaluations;
    ruleCalculations?: DynamicPriceCalculations;
}

export class BcmDynamicPrice {
    id?: number;

    boat?: Boat;
    berth?: Berth;
    tenantRelation?: TenantRelation;
    season?: Season;
    winterStorage?: WinterStorage;
    pier?: Pier;

    days?: number;
    overnights?: number;
    travelers?: number;

    ruleIndex?: number = null;
    ruleName?: string;
    rulePrice?: number;

    ruleEvaluations?: DynamicPriceRuleEvaluations;
    ruleCalculations?: DynamicPriceCalculations;

    matchingRuleEvaluations?: DynamicPriceRuleEvaluations;

    constructor(dto = {} as BcmDynamicPriceDto) {
        this.id = dto?.id;
        this.boat = dto?.boat ? new Boat(dto?.boat) : null;
        this.berth = dto?.berth ? new Berth(dto?.berth) : null;
        this.tenantRelation = dto?.tenantRelation ? new TenantRelation(dto?.tenantRelation) : null;
        this.season = dto?.season ? new Season(dto?.season) : null;
        this.winterStorage = dto?.winterStorage ? new WinterStorage(dto?.winterStorage) : null;
        this.pier = dto?.pier ? new Pier(dto?.pier) : null;

        this.days = dto?.days;
        this.overnights = dto?.overnights;
        this.travelers = dto?.travelers;

        this.ruleIndex = dto?.ruleIndex;
        this.rulePrice = dto?.rulePrice;
        this.ruleName = dto?.ruleName;
        this.ruleEvaluations = dto?.ruleEvaluations;
        this.ruleCalculations = dto?.ruleCalculations;

        this.matchingRuleEvaluations = {
            ...this.ruleEvaluations,
            evaluations: (this.ruleEvaluations?.evaluations || []).filter(e => e.isTrue)
        };
    }

}

export interface BcmDynamicPriceContext {
    person?: Person;
    company?: Company;
    boat?: Boat;
    travelers?: number;
    preselection?: { [key: string]: any };
    positions?: InvoicePosition[];
    fromDate?: Date;
    toDate?: Date;
}
