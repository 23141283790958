import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { BcmExportExcelApiService } from '@bcmApiServices/bcm-export-excel.api-service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BcmBaseFacade } from '@modules/bcm/@core/state-management/bcm-base.facade';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { ThemePalette } from '@angular/material/core';
import { BcmFinancialRecord, BcmFinancialRecordDto, FinancialRecordStatus } from '@shared/models/bcm-financial-record';
import { financialRecordsTableData } from '@modules/bcm/@core/state-management/financial-records/financial-records-table-data';
import { TablePredefinedFilter, TableRowAction } from '@modules/bcm/@core/state-management/bcm-base.types';
import { BcmTenantService } from '@modules/bcm/bcm-tenant.service';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { InvoiceTypes, ReceiptTypes } from '@shared/invoice-types';
import { SendEmailDialogComponent } from '@modules/bcm/accounting/invoices/dialogs/send-email-dialog/send-email-dialog.component';
import { dataURItoBlob } from '@shared/functions/datauri-to-blob';
import { U2bInformationDialogComponent } from '@sharedComponents/dialogs/information-dialog/information-dialog.component';
import { TranslationService } from '@core/translation/translation.service';
import { downloadFile } from '@shared/functions/download-file';
import print from 'print-js';
import { InvoicesService } from '@modules/bcm/accounting/invoices/invoices.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PayByCashDialogComponent } from '@sharedComponents/dialogs/pay-by-cash-dialog/pay-by-cash-dialog.component';
import { CapturePaymentDialogComponent } from '@modules/bcm/accounting/invoices/dialogs/capture-payment-dialog/capture-payment-dialog.component';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { PaymentsDialogComponent } from '@modules/bcm/accounting/invoices/dialogs/payments-dialog/payments-dialog.component';
import { BcmUserSettingsFacade } from '@bcmServices/settings/bcm-user-settings-facade';
import { AccountingService } from '@modules/bcm/accounting/accounting.service';
import { GetFromToRangeDialogComponent } from '@sharedComponents/dialogs/get-from-to-range-dialog/get-from-to-range-dialog.component';
import { startOfYear } from '@core/date.facade';
import { format } from '@core/date.facade';
import { U2bLoadingDialogComponent } from '@sharedComponents/dialogs/loading-dialog/loading-dialog.component';
import { EMPTY, forkJoin, of } from 'rxjs';
import { download } from 'ngx-operators';
import { saveAs } from 'file-saver';
import { DataFilterOperator } from '@core/datafilter/filter-operator';
import { DataFilterOperationType } from '@core/datafilter/filter-operation-type';
import { UserService } from '@core/services/user.service';
import { BcmBaseBulkChanges } from '@modules/bcm/@core/state-management/bcm-base-bulk-changes';
import { FinancialRecordsPaymentService } from '@modules/bcm/@core/state-management/financial-records/financial-records-payment.service';
import { InformationDialogService } from '@sharedComponents/dialogs/information-dialog/information-dialog.service';

// import { BcmUserTableSettingKey } from '@shared/models/bcm-settings-user';

@Injectable({providedIn: 'root'})
export class FinancialRecordsFacade extends BcmBaseFacade<BcmFinancialRecord, BcmFinancialRecordDto> {

    readonly resourcePath = 'financial-records';

    readonly resourceNameSingular = 'Beleg';

    readonly resourceNamePlural = 'Belege';

    readonly resourceIconPlural = 'insert_drive_file';

    readonly resourceIconSingular = 'insert_drive_file';

    readonly deletePermission = BcmUserPermission.INVOICES_DELETE;

    readonly readPermission = BcmUserPermission.INVOICES_READ;

    readonly writePermission = BcmUserPermission.INVOICES_WRITE;

    readonly headerActions = [
        {
            title: 'DATEV XML Export',
            onClick: () => this._bcmNavigationService.navigate(['accounting', 'invoices', 'datev-export']),
            tenantPermissions: [BcmTenantPermission.DATEV],
        },
        {
            title: 'Excel Export',
            onClick: () => this._bcmExportExcelApiService.exportFinancialRecords(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        },
        {
            title: 'ZIP Datei Export',
            icon: 'archive',
            onClick: () => this.zipExport(),
            showDividerBelow: true,
        },
        {
            title: 'Lastschrift erzeugen',
            onClick: () => this._accountingService.newSepaRun(),
            tenantPermissions: [BcmTenantPermission.SEPA],
        },
        {
            title: 'Lastschriften verwalten',
            onClick: () => this._bcmNavigationService.navigate(['accounting', 'sepa-runs']),
            tenantPermissions: [BcmTenantPermission.SEPA],
        }
    ];

    readonly rowActions: TableRowAction<BcmFinancialRecord>[] = [
        {
            title: 'Beleg anzeigen',
            icon: 'assignment',
            iconColor: 'accent' as ThemePalette,
            onClick: (record: BcmFinancialRecord) => {
                this._bcmNavigationService.navigate('accounting/invoices/view/' + record.number);
            },
        },
        {
            title: 'Per E-Mail versenden',
            icon: 'send',
            iconColor: 'accent' as ThemePalette,
            showButton: (record: BcmFinancialRecord) => {
                return this._bcmTenantService.checkPermission(BcmTenantPermission.INVOICE_MAILING) &&
                    (!!record.invoice?.id || !!record.receipt?.id);
            },
            onClick: (record: BcmFinancialRecord) => {
                this.onClickSendMail(record);
            },
        },
        {
            title: 'Als PDF herunterladen',
            icon: 'picture_as_pdf',
            iconColor: 'accent' as ThemePalette,
            onClick: (record: BcmFinancialRecord) => {
                this.onClickDownload(record);
            },
        },
        {
            title: 'Drucken',
            icon: 'print',
            iconColor: 'accent' as ThemePalette,
            onClick: (record: BcmFinancialRecord) => {
                this.onClickPrint(record);
            },
        },
        {
            title: 'Zahlung erfassen',
            icon: 'euro',
            iconColor: 'accent' as ThemePalette,
            showButton: (record: BcmFinancialRecord) => {
                return this._bcmTenantService.checkPermission(BcmTenantPermission.INVOICES_MARK_AS_PAID) &&
                    record.invoice?.id &&
                    record.invoice.invoiceStatus !== FinancialRecordStatus.Canceled;
            },
            disabled: (record: BcmFinancialRecord) => {
                return record.invoice.invoiceStatus === FinancialRecordStatus.Payed;
            },
            onClick: (record: BcmFinancialRecord) => {
                this.onChangeStatus(record, FinancialRecordStatus.Payed);
            },
        },
        {
            title: 'Zahlung bearbeiten',
            icon: 'playlist_add_check',
            iconColor: 'accent' as ThemePalette,
            showButton: (record: BcmFinancialRecord) => {
                return this._bcmTenantService.checkPermission(BcmTenantPermission.INVOICES_MARK_AS_PAID) &&
                    record.invoice?.id &&
                    record.invoice.invoiceStatus !== FinancialRecordStatus.Canceled &&
                    record.invoice.payments?.length > 0;
            },
            onClick: (record: BcmFinancialRecord) => {
                this.openPaymentsDialog(record);
            },
        },
        {
            title: 'Stornieren',
            icon: 'cancel',
            iconColor: 'warn' as ThemePalette,
            showButton: (record: BcmFinancialRecord) => {
                return record.invoice?.invoiceType === InvoiceTypes.Invoice ||
                    record.invoice?.invoiceType === InvoiceTypes.Crediting ||
                    record.receipt?.receiptType === ReceiptTypes.Receipt;
            },
            disabled: (record: BcmFinancialRecord) => {
                return !!(record.cancellationRecord?.number);
            },
            onClick: (record: BcmFinancialRecord) => {
                this.onChangeStatus(record, FinancialRecordStatus.Canceled);
            },
        },
    ];

    readonly predefinedFilter: TablePredefinedFilter<BcmFinancialRecord>[] = [
        {name: 'Alle ' + TranslationService.translate('invoices'), icon: 'search', filters: undefined},
        {
            name: 'Offene SEPA ' + TranslationService.translate('invoices'),
            icon: 'search',
            filters: [
                {
                    property: 'isSEPA',
                    operator: DataFilterOperator.Equal,
                    operationType: DataFilterOperationType.And,
                    value: true,
                    // todo: how to simplify this to get the columnDefinition?
                    columnDefinition: financialRecordsTableData.columnDefinitions.find(item => item.property === 'isSEPA')
                }
            ]
        },
    ];

    readonly bulkChanges = new BcmBaseBulkChanges<BcmFinancialRecord>()
        .setButtons(
            {
                title: 'Belege bezahlt',
                icon: 'euro',
                iconColor: 'accent' as ThemePalette,
                resetSelectionAfterSuccess: true,
                onClick: (financialRecords: BcmFinancialRecord[]) =>
                    this._financialRecordsPaymentService
                        .markInvoicesAsPaid(...financialRecords)
                        .pipe(tap(() => this.reloadCurrentPage())),
                showButton: (financialRecords: BcmFinancialRecord[]) => {
                    const validFinancialRecords: BcmFinancialRecord[] = financialRecords
                        .filter(record => record.invoice != null)
                        .filter(record => record.status !== FinancialRecordStatus.Canceled)
                        .filter(record => [InvoiceTypes.Invoice, InvoiceTypes.Crediting].includes(record.invoice.invoiceType));

                    return validFinancialRecords?.length && this._userService.hasAllPermissionsOf(BcmUserPermission.INVOICES_WRITE);
                },
            },
            // several bugs I had no time to check; I also removed queue system because it did not work as expected on the linux server
            // {
            //     title: 'Belege stornieren',
            //     icon: 'cancel',
            //     iconColor: 'warn' as ThemePalette,
            //     resetSelectionAfterSuccess: true,
            //     onClick: (financialRecords: BcmFinancialRecord[]) => this.cancelMultipleInvoices(financialRecords),
            //     showButton: (financialRecords: BcmFinancialRecord[]) => {
            //         const validFinancialRecords: BcmFinancialRecord[] = this._filterValidInvoices(financialRecords);
            //
            //         return validFinancialRecords?.length && this._userService.hasAllPermissionsOf(BcmUserPermission.INVOICES_DELETE);
            //     },
            // },
            // several bugs I had no time to check; I also removed queue system because it did not work as expected on the linux server
            // {
            //     title: 'Barbelege stornieren',
            //     icon: 'cancel',
            //     iconColor: 'warn' as ThemePalette,
            //     resetSelectionAfterSuccess: true,
            //     onClick: (financialRecords: BcmFinancialRecord[]) => this.cancelMultipleReceipts(financialRecords),
            //     showButton: (financialRecords: BcmFinancialRecord[]) => {
            //         const validFinancialRecords: BcmFinancialRecord[] = this._filterValidReceipts(financialRecords);
            //
            //         return validFinancialRecords?.length && this._userService.hasAllPermissionsOf(BcmUserPermission.INVOICES_DELETE);
            //     },
            // },
            {
                title: '',
                icon: 'info_outline',
                iconColor: 'primary' as ThemePalette,
                onClick: () => this.showSpacialInfo(),
            },
        )
        .setLabelFn((records: BcmFinancialRecord[]) => {
            const invoices = records.filter(record => record.invoice !== null);
            const receipts = records.filter(record => record.receipt !== null);
            const result = [];

            if (invoices?.length) {
                result.push(`${invoices.length} ${(invoices?.length === 1 ? 'Beleg' : 'Belege')}`);
            }

            if (receipts?.length) {
                result.push(`${receipts.length} ${(receipts?.length === 1 ? 'Barbeleg' : 'Barbelege')}`);
            }

            return result.join('<br>');
        })
        .setDisableSelectionCheckBoxFn((record: BcmFinancialRecord, selectedRecords: BcmFinancialRecord[]) => {
            return (selectedRecords?.length && selectedRecords[0].receipt && !record.receipt)
                || (selectedRecords?.length && selectedRecords[0].invoice && !record.invoice);
        });

    constructor(appNotificationService: AppNotificationService,
                confirmDialogService: ConfirmDialogService,
                bcmUserSettingsFacade: BcmUserSettingsFacade,
                httpClient: HttpClient,
                bcmService: BcmService,
                bcmNavigationService: BcmNavigationService,
                private _userService: UserService,
                private _bcmTenantService: BcmTenantService,
                private _bcmNavigationService: BcmNavigationService,
                private _bcmExportExcelApiService: BcmExportExcelApiService,
                private _invoicesService: InvoicesService,
                private _matDialog: MatDialog,
                private _accountingService: AccountingService,
                private _appNotificationService: AppNotificationService,
                private _confirmDialogService: ConfirmDialogService,
                private _financialRecordsPaymentService: FinancialRecordsPaymentService,
                private _informationDialogService: InformationDialogService,
    ) {
        super(
            BcmFinancialRecord,
            financialRecordsTableData,
            bcmNavigationService,
            appNotificationService,
            confirmDialogService,
            bcmUserSettingsFacade,
            httpClient,
            bcmService
        );

        this.rowClickHandler = (record: BcmFinancialRecord) => {
            this._bcmNavigationService.navigate('accounting/invoices/view/' + record.number);
        };
    }

    onChangeStatus(record: BcmFinancialRecord, newInvoiceStatus: FinancialRecordStatus): void {

        const invoice = record.invoice;
        const receipt = record.receipt;

        if (receipt && newInvoiceStatus === FinancialRecordStatus.Canceled) {

            if (receipt.positions.length === 0) {

                this._informationDialogService
                    .setTheme('warn')
                    .setTitle('Stornierung nicht möglich')
                    .setBody(`Beleg ${receipt.receiptNumber} kann nicht storniert, da es sich um eine Teilzahlung einer bestehenden ${TranslationService.translate('invoice')} handelt.`)
                    .open();
                return;
            }

            const hasPersonOrCompany = !!receipt.person?.id || !!receipt.company?.id;
            const dialog = this._confirmDialogService
                .setTheme('warn')
                .setBody(`Beleg ${receipt.receiptNumber} wirklich stornieren?`);

            if (hasPersonOrCompany) {
                dialog.appendInputToBody({
                    name: 'addPositionsBackToCoaster',
                    type: 'checkbox',
                    label: TranslationService.translate('addPositionsBackToCoaster'),
                    defaultValue: false
                });
            }

            dialog.openAndReturnResult<{ addPositionsBackToCoaster: boolean }>()
                .subscribe(async result => {
                    if (!result) {
                        return;
                    }

                    this._matDialog
                        .open(PayByCashDialogComponent, {
                            data: {
                                positions: receipt.positions,
                                person: record.person,
                                company: record.company,
                                receiptToCancel: receipt,
                                addPositionsBackToCoaster: hasPersonOrCompany && result.addPositionsBackToCoaster
                            },
                            disableClose: true
                        })
                        .afterClosed()
                        .subscribe(() => this.reloadCurrentListOrPage());
                });

        } else if (invoice) {
            switch (newInvoiceStatus) {
                case FinancialRecordStatus.Payed:
                    this._matDialog
                        .open(CapturePaymentDialogComponent, {data: {invoice}})
                        .afterClosed()
                        .subscribe(() => this.reloadSelected(record));
                    break;
                case FinancialRecordStatus.Canceled:

                    const hasPersonOrCompany = !!invoice.person?.id || !!invoice.company?.id;
                    const dialog = this._confirmDialogService
                        .setTheme('warn')
                        .setBody(`${invoice.invoiceType === InvoiceTypes.Crediting ? 'Gutschrift' : TranslationService.translate('invoice')} ${invoice.invoiceNumber} wirklich stornieren?`);

                    if (hasPersonOrCompany) {
                        dialog.appendInputToBody({
                            name: 'addPositionsBackToCoaster',
                            type: 'checkbox',
                            label: TranslationService.translate('addPositionsBackToCoaster'),
                            defaultValue: false
                        });
                    }

                    dialog
                        .appendInputToBody({
                            name: 'cancellationReason',
                            type: 'text',
                            label: 'Grund',
                        })
                        .openAndReturnResult<{ addPositionsBackToCoaster: boolean, cancellationReason: string }>()
                        .subscribe(result => {
                            if (!result) {
                                return;
                            }

                            this._invoicesService
                                .cancelInvoice(
                                    invoice,
                                    hasPersonOrCompany && result.addPositionsBackToCoaster,
                                    result.cancellationReason
                                )
                                .subscribe((cancellationInvoice) => {
                                    this.reloadCurrentListOrPage();
                                    this._appNotificationService.showSuccess(`${invoice.invoiceType === InvoiceTypes.Crediting ? 'Gutschrift' : TranslationService.translate('invoice')} storniert. ${invoice.invoiceType === InvoiceTypes.Crediting ? 'Stornogutschrift' : TranslationService.translate('cancellationInvoice')} ${cancellationInvoice.invoiceNumber} erzeugt.`);
                                });
                        });
                    break;
            }
        }
    }

    openPaymentsDialog(record: BcmFinancialRecord): void {
        this._matDialog
            .open(PaymentsDialogComponent, {data: {invoice: record.invoice}})
            .afterClosed()
            .subscribe(() => this.reloadSelected(record));
    }

    async onClickSendMail(record: BcmFinancialRecord): Promise<void> {

        const invoice = record.invoice;

        const person = record.person?.id && record.person;
        const company = record.company?.id && record.company;

        const invoicesByMail = (person || company)?.invoicesByMail;
        const mails = (person || company)?.mails;
        const invoiceId = invoice.id;

        if (invoicesByMail || (person || company) == null) {
            const sendEmailDialogRef = this._matDialog.open(SendEmailDialogComponent, {
                data: {invoice, mails, invoiceId}
            });

            await sendEmailDialogRef.afterClosed().toPromise();
        } else {
            this._appNotificationService.showError('In den Stammdaten ist kein Versand per E-Mail hinterlegt.');
        }
    }

    async onClickDownload(record: BcmFinancialRecord): Promise<void> {

        let pdfBlob;

        if (record.invoice) {
            pdfBlob = await this._invoicesService.getPdfFile(record.invoice).toPromise();
        } else if (record.receipt) {
            pdfBlob = dataURItoBlob(record.receipt.pdfDataUrl);
        } else {
            return;
        }

        if (!pdfBlob || !pdfBlob.size) {
            const confirmDialogRef = this._matDialog.open(U2bInformationDialogComponent);

            confirmDialogRef.componentInstance.dialogTitle = `Download nicht möglich`;
            confirmDialogRef.componentInstance.dialogBody = `Für diesen Beleg ist kein PDF hinterlegt.`;

            return;
        }

        const documentTitle = `${TranslationService.translate(record.type)} ${record.number} - ${(record.person?.fullNameBackward || record.company?.name)}.pdf`;

        downloadFile(pdfBlob, documentTitle);
    }

    async onClickPrint(record: BcmFinancialRecord): Promise<void> {

        let pdfBlob;

        if (record.invoice) {
            pdfBlob = await this._invoicesService.getPdfFile(record.invoice).toPromise();
        } else if (record.receipt) {
            pdfBlob = dataURItoBlob(record.receipt.pdfDataUrl);
        } else {
            return;
        }

        if (!pdfBlob || !pdfBlob.size) {
            const confirmDialogRef = this._matDialog.open(U2bInformationDialogComponent);

            confirmDialogRef.componentInstance.dialogTitle = `Drucken nicht möglich`;
            confirmDialogRef.componentInstance.dialogBody = `Für diese${TranslationService.translate(record.type)} ist kein PDF hinterlegt.`;

            return;
        }

        const documentTitle = `${TranslationService.translate(record.type)} ${record.number} - ${(record.person?.fullNameBackward || record.company?.name)}.pdf`;

        // this.electronService.printReceipt({just: 'a', test: '!'});

        print({printable: URL.createObjectURL(pdfBlob), documentTitle});
    }

    async zipExport(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const now = new Date();
        dialogRef.componentInstance.allowTypeAll = false;
        dialogRef.componentInstance.dateFieldsOptional = false;
        dialogRef.componentInstance.fromDateDefault = startOfYear(now);
        dialogRef.componentInstance.fromDateMax = now;
        dialogRef.componentInstance.toDateDefault = now;
        dialogRef.componentInstance.toDateMax = now;

        const range = await dialogRef
            .afterClosed()
            .toPromise();

        if (range === false) {
            return;
        }

        const params = new HttpParams()
            .set('from', range?.from ? format(range?.from, 'yyyy-MM-dd') : '')
            .set('to', range?.to ? format(range.to, 'yyyy-MM-dd') : '');

        const loadingDialogRef = this._matDialog.open(U2bLoadingDialogComponent);
        loadingDialogRef.componentInstance.header = `Erstelle ZIP mit Belegen`;
        loadingDialogRef.componentInstance.download$ = this._invoicesService
            .getZipExport(params)
            .pipe(catchError(() => {
                this._appNotificationService.showError(
                    'Es ist ein unerwarteter Fehler aufgetreten. ' +
                    'Sollte es erneut zu einem Fehler kommen, wende Dich bitte an unseren Support.'
                );
                return EMPTY;
            }))
            .pipe(download((data) => saveAs(data, `Belege_${format(range?.from, 'dd.MM.yyyy')}-${format(range?.to, 'dd.MM.yyyy')}.zip`)))
            .pipe(finalize(() => loadingDialogRef.close()));
    }

    markMultipleAsPaid(records: BcmFinancialRecord[]) {
        return of(undefined);

        // return this._matDialog
        //     .open(CapturePaymentDialogComponent, {data: {invoice}})
        //     .afterClosed()
        //     .subscribe(() => );
    }

    cancelMultipleInvoices(records: BcmFinancialRecord[]) {

        // excluding receipts
        // only cancel when not already cancelled
        records = records
            .filter(record => record.invoice != null)
            .filter(record => record.status !== FinancialRecordStatus.Canceled);

        return this._confirmDialogService
            .setTheme('warn')
            .setBody(
                `<p>Folgende Belege wirklich stornieren?</p>` +
                `<ul>${records.map(record => `<li>${record.number}</li>`).join('')}</ul>`
            )
            .appendInputToBody({
                name: 'addPositionsBackToCoaster',
                type: 'checkbox',
                label: TranslationService.translate('addPositionsBackToCoaster'),
                defaultValue: false
            })
            .appendInputToBody({
                name: 'cancellationReason',
                type: 'text',
                label: 'Grund',
            })
            .openAndReturnResult<{
                addPositionsBackToCoaster: boolean,
                cancellationReason: string
            }>()
            .pipe(
                switchMap(result => {
                    if (!result) {
                        return EMPTY;
                    }

                    return forkJoin(
                        records.map(record => {
                            return this._invoicesService.cancelInvoice(
                                record.invoice,
                                result.addPositionsBackToCoaster,
                                result.cancellationReason
                            );
                        })
                    );
                }),
                tap(() => {
                    this.reloadCurrentListOrPage();
                    this._appNotificationService.showSuccess(`Belege wurden storniert.`);
                })
            );
    }

    cancelMultipleReceipts(records: BcmFinancialRecord[]) {

        alert('Storno von mehreren Barbelegen noch nicht implementiert!');

        return of(undefined);
        // // excluding receipts
        // // only cancel when not already cancelled
        // records = records
        //     .filter(record => record.receipt != null)
        //     .filter(record => record.status !== FinancialRecordStatus.Canceled);
        //
        // return this._confirmDialogService
        //     .setTheme('warn')
        //     .setBody(
        //         `<p>Folgende Barbelege wirklich stornieren?</p>` +
        //         `${records.map(record => record.number).join(', ')}`
        //     )
        //     .appendInputToBody({
        //         name: 'addPositionsBackToCoaster',
        //         type: 'checkbox',
        //         label: TranslationService.translate('addPositionsBackToCoaster'),
        //         defaultValue: false
        //     })
        //     .appendInputToBody({
        //         name: 'cancellationReason',
        //         type: 'text',
        //         label: 'Grund',
        //     })
        //     .openAndReturnResult<{
        //         addPositionsBackToCoaster: boolean,
        //         cancellationReason: string
        //     }>()
        //     .pipe(
        //         switchMap(result => {
        //             if (!result) {
        //                 return EMPTY;
        //             }
        //
        //             return forkJoin(
        //                 records.map(record => {
        //                     return this._invoicesService.cancelInvoice(
        //                         record.invoice,
        //                         result.addPositionsBackToCoaster,
        //                         result.cancellationReason
        //                     );
        //                 })
        //             );
        //         }),
        //         tap(() => {
        //             this.reloadCurrentListOrPage();
        //             this._appNotificationService.showSuccess(`Belege wurden storniert.`);
        //         })
        //     );
    }

    showSpacialInfo() {
        this._informationDialogService
            .setTitle('Info zur Selektierung')
            .setBody(
                '<p>Aus technischen Gründen können Belege und Barbelege aktuell nicht gleichzeitig selektiert werden.</p>' +
                '<p>Mit Selektierung der ersten CheckBox werden die CheckBoxen der Zeilen mit unterschiedlichem Belegtyp ausgeblendet.</p>' +
                '<p>Bei Auswahl der CheckBox "Alles auswählen" im Kopfbereich der Tabelle, ist der Belegtyp der aktuellen Selektierung oder die der ersten Zeile ausschlaggebend.</p>'
            )
            .open();
    }

    private _filterValidInvoices(financialRecords: BcmFinancialRecord[]): BcmFinancialRecord[] {
        return financialRecords
            .filter(record => record.invoice != null)
            .filter(record => record.status !== FinancialRecordStatus.Canceled)
            .filter(record => [InvoiceTypes.Invoice, InvoiceTypes.Crediting].includes(record.invoice.invoiceType));

    }

    private _filterValidReceipts(financialRecords: BcmFinancialRecord[]): BcmFinancialRecord[] {
        return financialRecords
            .filter(record => record.receipt != null)
            .filter(record => record.status !== FinancialRecordStatus.Canceled)
            .filter(record => [ReceiptTypes.Receipt].includes(record.receipt.receiptType));
    }
}
