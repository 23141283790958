import { EnvironmentInterface } from '@env/environment.interface';

// Add here your keycloak setup infos
const keycloakConfig = {
    url: 'https://auth-test.up2boat.com/realms/up2boat',
    clientId: 'u2b-web',
};

export const environment: EnvironmentInterface = {
    name: 'test2',
    isProd: false,
    production: true,
    showTestEnvironmentBanner: true,
    hmr: false,
    bcm: {
        supportMail: 'support@up2boat.com',
        supportPhone: '+49620295304801',
        supportPhoneBeautified: '+49&nbsp;(0)&nbsp;6202&nbsp;953&nbsp;048-01'
    },
    endpoints: {
        up2boat: {
            api: 'https://u2b-api.dev.up2boat.com/api/v1/'
        },
        u2b: {
            api: 'https://test2-api.up2boat.net/api/u2b/v1/',
            apiAdmin: 'https://test2-api.up2boat.net/api/u2b/admin/v1/'
        },
        bcm: {
            api: 'https://test2-api.up2boat.net/api/bcm/v1/',
            files: 'https://test2-api.up2boat.net/api/bcm/v1/files'
        },
        wp: 'https://www.up2boat.com/wp-json/wp/v2/',
        webSocket: 'https://test2-api.up2boat.net',
        pegelOnline: 'https://www.pegelonline.wsv.de',
    },
    keycloakConfig,
    appVersion: '{{APP_VERSION}}',
    appBuildNumber: '{{APP_BUILD_NUMBER}}',
    appVersionCheckURL: '/version.json',
    appVersionCheckInterval: 1000 * 60 * 5,
    dmyv: {
        api: 'https://test2-api.up2boat.net/api/dmyv/v1/',
        rolesWithAccess: ['dmyv_admin', 'dmyv_sbv_admin']
    }
};
