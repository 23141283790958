import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { Salutation } from '@shared/models/salutation';
import { Person } from '@shared/models/person';
import { debounceTime, distinctUntilChanged, map, skip, startWith, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { disableControlsByName } from '@shared/functions/form/disable-controls';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { isFunction } from '@shared/functions/is-function';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { PersonApiService } from '@modules/bcm/@shared/services';
import { HttpParams } from '@angular/common/http';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PersonEditorDialogComponent } from './person-editor-dialog/person-editor-dialog.component';

@Component({
    selector: 'form-widget-person',
    templateUrl: './form-widget-person.component.html'
})
export class FormWidgetPersonComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject<any>();

    @Input()
    appearance: MatFormFieldAppearance = 'outline';

    @Input()
    headline = 'Person wählen <small>oder</small> neu erfassen';

    @Input()
    inputLabel = 'Nach Person suchen...';

    @Input()
    person$: Observable<Person>;

    @Input()
    optionalFields: boolean;

    @Input()
    slimmedView = false;

    @Input()
    canCreate = true;

    @Input()
    personId: number;

    @Input()
    editable = false;

    @Input()
    disabled = false;

    @Output()
    personChanged: EventEmitter<Person> = new EventEmitter<Person>();

    personFormGroup: UntypedFormGroup;

    salutation = Salutation;

    person: Person;

    loadingPersons: boolean;

    persons: Person[];

    filteredPersons$: Observable<Person[]>;

    isSaving = false;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private formGroupDirective: FormGroupDirective,
        private _personApiService: PersonApiService,
        private _dialog: MatDialog
    ) {
    }

    ngOnInit(): void {

        if (!this.canCreate) {
            this.headline = 'Person wählen';
        }

        this._createForm();
        this._loadPersons();

        if (this.formGroupDirective?.form) {
            this.formGroupDirective.form.addControl('personForm', this.personFormGroup);
        }

        if (isFunction(this.person$?.subscribe)) {
            this.person$
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    distinctUntilChanged((previous, current) => previous?.id === current?.id)
                )
                .subscribe(person => {
                    this.personFormGroup.patchValue({person, ...person});

                    if (person?.phones && person.phones.length) {
                        this.personFormGroup.get('phone').patchValue(person.phones[0]);
                    }

                    this.personFormGroup.markAsDirty();
                });
        }
    }

    ngOnDestroy(): void {
        if (this.formGroupDirective?.form) {
            this.formGroupDirective.form.removeControl('personForm');
        }
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    onClickRemovePerson(): void {
        this.person = null;
        this.personFormGroup.get('person').setValue(null);
        this.personFormGroup.reset();
        this.personFormGroup.enable();
    }

    openEditDialog(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        const disabled = this.personFormGroup.get('person').disabled;

        this._dialog.open(PersonEditorDialogComponent, {
            data: {
                parentFormGroup: this.formGroupDirective.form,
                personFormGroup: this.personFormGroup,
                person: this.person,
                optionalFields: this.optionalFields,
                appearance: this.appearance
            },
            disableClose: true,
        }).afterClosed().subscribe((person: Person) => {
            if (person) {
                if (this.person.id === person.id) {
                    this.personFormGroup.markAsPristine();
                    this.personFormGroup.markAsUntouched();
                }
                this.person = person;
            }
            if (disabled) {
                this.personFormGroup.get('person').disable();
            }
        });
    }

    public displayPersonWith(person: Person): string {
        return person ? person.toDropdownString() : '';
    }

    private _createForm(): void {
        this.personFormGroup = this._formBuilder.group({
            person: [null],
            salutation: [null, [U2bValidators.required('Bitte Anrede angeben')]],
            title: [null],
            firstName: [null, [U2bValidators.required('Bitte Vornamen angeben')]],
            lastName: [null, [U2bValidators.required('Bitte Nachnamen angeben')]],
            street: [
                null,
                this.optionalFields ? [] : [U2bValidators.required('Bitte Strasse angeben')]
            ],
            postCode: [
                null,
                this.optionalFields ? [] : [U2bValidators.required('Bitte PLZ angeben')]
            ],
            city: [
                null,
                this.optionalFields ? [] : [U2bValidators.required('Bitte Stadt angeben')]
            ],
            mail: [
                null,
                [U2bValidators.email('Bitte überprüfe die eingegebene E-Mail-Adresse. Beachte das E-Mail Format', true)]
            ],
            phone: this._formBuilder.group({
                type: [null],
                number: [null],
            }),
            note: [null, [U2bValidators.maxLength(1024)]]
        });


        this.personFormGroup
            .get('person')
            .valueChanges
            .pipe(
                skip(1),
                takeUntil(this._unsubscribeAll),
                distinctUntilChanged((previous, current) => previous?.id === current?.id)
            )
            .subscribe((person) => {

                this.person = person;
                this.personChanged.emit(person);

                if (person && person.id) {
                    disableControlsByName(
                        this.personFormGroup,
                        ['salutation', 'title', 'firstName', 'lastName', 'street', 'postCode', 'city', 'mail', 'phone', 'note']
                    );
                } else {
                    enableControlsByName(
                        this.personFormGroup,
                        ['salutation', 'title', 'firstName', 'lastName', 'street', 'postCode', 'city', 'mail', 'phone', 'note'],
                        true
                    );
                }
            });

        if (this.disabled) {
            this.personFormGroup.disable();
        }

    }

    private _loadPersons(): void {
        this.persons = [];
        this.loadingPersons = true;
        this._personApiService
            .getAll(new HttpParams().set('includeBoats', 'true'))
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap(() => this.loadingPersons = false)
            )
            .subscribe(persons => {
                this.persons = persons;
                if (this.personId) {
                    this.person = persons.find(person => person.id === this.personId);
                    this.personFormGroup.patchValue({person: this.person});
                }
            });

        this.filteredPersons$ = this.personFormGroup.get('person').valueChanges
            .pipe(
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? Person.filterPersons(this.persons, value) : this.persons)
            );
    }
}
