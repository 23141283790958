import { Injectable } from '@angular/core';
import { BcmService } from '@modules/bcm/bcm.service';
import { Tenant } from '@shared/models/tenant';
import { TseFiscalClient } from '@shared/models/tse/TseFiscalClient';
import { Observable, throwError } from 'rxjs';
import { TseFiscalResponse } from '@shared/models/tse/TseFiscalResponse';
import { TsePayment } from '@shared/models/tse/TsePayment';
import { UserService } from '@core/services/user.service';
import { TseDocumentType } from '@shared/models/tse/TseDocumentType';
import { TseDocument } from '@shared/models/tse/TseDocument';
import { TsePaymentType } from '@shared/models/tse/TsePaymentType';
import { TseDocumentPositionType } from '@shared/models/tse/TseDocumentPositionType';
import { TseBusinessTransactionType } from '@shared/models/tse/TseBusinessTransactionType';
import { v4 as uuidv4 } from 'uuid';
import { BcmCashRegister } from '@shared/models/bcm-cash-register';
import { BcmCashRegistersApiService } from '@bcmApiServices/bcm-cash-registers-api.service';
import { BcmReceipt } from '@shared/models/bcm-receipt';
import { StaticGlobals } from '@modules/bcm/@shared/static-globals';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmPaymentTypeFromString, Person } from '@shared/models/person';
import { Company } from '@shared/models/company';
import { BcmFinancialRecordPdfData } from '@shared/models/bcm-financial-record';
import { TseReferenceType } from '@shared/models/tse/TseReferenceType';
import * as countries from 'i18n-iso-countries';
import { TsePartnerIdentificationType } from '@shared/models/tse/TsePartnerIdentificationType';
import { TsePartnerType } from '@shared/models/tse/TsePartnerType';
import { BcmInvoiceWdTemplate } from '@shared/models/bcm-invoice-wd-template';
import { getTaxRateValue } from '@core/functions/get-tax-rate-value';
import { roundCurrency } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';
import { Invoice } from '@shared/models/invoice';
import { TseApiService } from '@bcmApiServices/tse-api.service';
import { BcmCashRegisterUser } from '@shared/models/bcm-cash-register-user';
import { TseAutomaticVatCalculation } from '@shared/models/tse/TseAutomaticVatCalculation';
import { Country } from '@shared/models/country';
import { getAddressString } from '@shared/functions/get-address-string';

@Injectable({
    providedIn: 'root'
})
export class CashRegisterService {

    private _tenant: Tenant;
    private readonly _userId: string;
    private readonly _userFullName: string;
    private readonly _userFirstName: string;
    private readonly _userLastName: string;

    constructor(
        _bcmService: BcmService,
        private _userService: UserService,
        private _cashRegisterApi: BcmCashRegistersApiService,
        private _tseApiService: TseApiService,
    ) {
        this._tenant = _bcmService.tenant;
        this._userId = this._userService.user.emailHash;
        this._userFullName = this._userService.userFullName;
        this._userFirstName = this._userService.userFirstName;
        this._userLastName = this._userService.userLastName;

        countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
    }

    getFiscalClient(cashRegister: BcmCashRegister): Observable<TseFiscalClient> {
        return this._tseApiService.getClient(cashRegister);
    }

    createCashRegister(storeNumber: string, terminalNumber: string, title: string, note: string, cashRegisterUsers: BcmCashRegisterUser[]): Observable<BcmCashRegister> {
        return this._tseApiService.createCashRegister(storeNumber, terminalNumber, title, note, cashRegisterUsers);
    }

    openCashRegister(cashRegister: BcmCashRegister, amount: number): Observable<void> {
        return this._tseApiService.openCashRegister(cashRegister, amount);
    }

    closeCashRegister(cashRegister: BcmCashRegister, amount: number,
                      differenceSum: number = 0, differenceReason: string | null = null,
                      cashAdd: number, cashLift: number,
                      closeMonth: boolean = false): Observable<void> {

        return this._tseApiService.closeCashRegister(cashRegister, amount, differenceReason, differenceSum, cashAdd, cashLift, closeMonth);
    }


    bookCashLift(cashRegister: BcmCashRegister, amount: number): Observable<TseFiscalResponse> {
        return this._tseApiService.bookCashLift(cashRegister, amount);
    }

    getActualStock(cashRegister: BcmCashRegister): Observable<TsePayment[]> {
        return this._tseApiService.getActualStock(cashRegister);
    }

    getLoggedInCashRegisterForUser(): Observable<BcmCashRegister> {
        return this._cashRegisterApi.getLoggedInCashRegisterForUser();
    }

    addCancellationReceipt(
        cashRegister: BcmCashRegister,
        receiptToCancel: BcmReceipt,
        paymentType: TsePaymentType,
        address: string,
        person?: Person,
        company?: Company,
        wordTemplate?: BcmInvoiceWdTemplate,
        addPositionsBackToCoaster?: boolean): Observable<BcmReceipt> {
        const positions = receiptToCancel.positions.map(p => ({...p, price: p.price * (-1)} as InvoicePosition));
        return this.addReceipt(
            cashRegister,
            positions,
            paymentType,
            address,
            person,
            company,
            wordTemplate,
            `STORNO für Beleg ${receiptToCancel.receiptNumber}`,
            receiptToCancel,
            addPositionsBackToCoaster);
    }


    addReceipt(cashRegister: BcmCashRegister,
               positions: InvoicePosition[],
               paymentType: TsePaymentType,
               address: string,
               person?: Person,
               company?: Company,
               wordTemplate?: BcmInvoiceWdTemplate,
               note?: string,
               receiptToCancel?: BcmReceipt,
               addPositionsBackToCoaster?: boolean): Observable<BcmReceipt> {

        if (!positions?.length || positions.length < 1) {
            return throwError(() => [`Es wurden keine Positionen angegeben.`]);
        }

        const totalAmount = this.getTotal(positions);

        const date = new Date();
        const document: TseDocument = {
            cancellationDocument: !!(receiptToCancel?.id),
            documentReference: (receiptToCancel?.id) ? {
                documentType: TseDocumentType.RECEIPT,
                documentBookDate: receiptToCancel.tseDocument.bookDate,
                documentId: receiptToCancel.tseDocument.documentId,
                documentGuid: receiptToCancel.tseDocument.documentGuid,
                referenceType: TseReferenceType.CANCELLATION,
                documentNumber: receiptToCancel.tseDocument.documentNumber,
                fiscalDocumentNumber: receiptToCancel.tseDocument.fiscalDocumentNumber
            } : null,
            documentGuid: uuidv4(),
            documentId: 'receiptIdentifier',
            documentNumber: 'receiptIdentifier',
            fiscalDocumentNumber: 1,
            FiscalDocumentStartTime: date.getTime(),
            fiscalDocumentRevision: 1,
            isTraining: false,
            positionCount: positions.length,
            uniqueClientId: cashRegister.clientId,
            createDate: date.toUTCString(),
            bookDate: date.toUTCString(),
            documentType: TseDocumentType.RECEIPT,
            user: {
                id: this._userId,
                caption: this._userFullName,
                firstName: this._userFirstName,
                lastName: this._userLastName

            },
            partner: (Math.abs(totalAmount) >= 250 && (person?.id || company?.id)) ?
                {
                    id: `${person?.id || company?.id}-${person?.fullName || company?.fullName}`,
                    caption: person?.fullName || company?.fullName,
                    street: person?.streetWithoutNumber || company?.streetWithoutNumber,
                    streetNumber: person?.streetNumber || company?.streetNumber,
                    city: person?.city || company?.city,
                    postalCode: person?.postCode || company?.postCode,
                    countryCode: countries.getAlpha3Code(person?.country?.de || company?.country?.de || 'Deutschland', 'de'),
                    identificationType: TsePartnerIdentificationType.OTHER,
                    partnerType: TsePartnerType.CUSTOMER,
                    partnerClassification: 'Customer'
                } :
                null,
            positions: positions.map((position, index) => {
                const taxRateValue = getTaxRateValue(position);
                const additionalFields: Record<string, string> = {};

                // RF container throws 500 error, if some additionalFiled values are
                // null or undefined or anything else but string.
                if (position.account) {
                    additionalFields.account = String(position.account);
                }

                if (position.unit?.name) {
                    additionalFields.unit = String(position.unit.name);
                }

                if (position.product?.name || position.title) {
                    additionalFields.productName = String(position.product?.name || position.title);
                }

                return {
                    itemCaption: position.title,
                    itemShortCaption: null,
                    discounts: [],
                    type: TseDocumentPositionType.ITEM,
                    useSubItemVatCalculation: true,
                    subItems: null,
                    inHouse: true,
                    quantity: position.quantity,
                    quantityUnit: null,
                    itemId: position.itemNumber ||
                        (position.product?.id ? (position.product?.itemNumber || 'ID_' + String(position.product.id)) : 'UUID_' + uuidv4()),
                    baseNetValue: this._getNetValue(position),
                    baseGrossValue: this._getGrossValue(position),
                    baseTaxValue: this._getTaxValue(position),
                    businessTransactionType: TseBusinessTransactionType.REVENUE,
                    vatIdentification: position.tseVatIdentificationId, // TODO set later in BE
                    vatPercent: taxRateValue,
                    netValue: this._getNetValue(position),
                    grossValue: this._getGrossValue(position),
                    taxValue: this._getTaxValue(position),
                    accountingIdentifier: null,
                    positionNumber: index,
                    positionReference: null,
                    cancellationPosition: false,
                    additionalFields
                };
            }),
            payments: [
                {
                    amount: totalAmount,
                    currencyIsoCode: 'EUR',
                    caption: 'PAYMENT',
                    uniqueReadablePaymentIdentifier: `RECEIPT-${new Date().getTime()}-${cashRegister.clientId}`,
                    foreignAmount: 0.0,
                    foreignAmountExchangeRate: 0.0,
                    paymentType
                }
            ]
        };

        const totalTaxes: { [key: number]: number } = {};
        let totalTax = 0;

        for (const position of positions.filter(p => p.taxRateValue > 0 || p.taxRate.value > 0)) {
            const taxRateValue = getTaxRateValue(position);
            const taxValue = roundCurrency(taxRateValue);
            const totalTaxesForValue = totalTaxes[taxValue] || 0;
            const positionTaxValue = this._getTaxValue(position);
            totalTaxes[taxValue] = roundCurrency(totalTaxesForValue + positionTaxValue);
            totalTax += positionTaxValue;
        }

        totalTax = roundCurrency(totalTax);

        const pdfData: BcmFinancialRecordPdfData = {
            wordTemplate,
            address: address,
            bcmSettings: null,
            date: null,
            debtorNumber: person?.identNumber || company?.identNumber,
            iban: person?.IBAN || company?.IBAN,
            invoiceLogo: null,
            invoiceNumber: null,
            paymentType: BcmPaymentTypeFromString[paymentType],
            positions: positions,
            priceData: {
                totalTaxes,
                totalTax,
                totalGrossPrice: roundCurrency(
                    positions
                        .map(p => this._getGrossValue(p))
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)),
                totalNetPrice: roundCurrency(positions
                    .map(p => this._getNetValue(p))
                    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)),
                totalTaxFreeProductsPrice: roundCurrency(positions
                    .filter(p => p.taxRateValue === 0 || p.taxRate.value === 0)
                    .map(p => p.price)
                    .reduce((previousValue, currentValue) => previousValue + currentValue, 0))
            },
            tenant: null,
            tenantRelation: null,
            note
        };

        return this._tseApiService.createDocument(
            cashRegister,
            document,
            positions,
            totalAmount,
            totalTax,
            pdfData,
            paymentType,
            person,
            company,
            receiptToCancel,
            addPositionsBackToCoaster
        );

    }

    addInvoicePayIn(cashRegister: BcmCashRegister,
                    invoice: Invoice,
                    paymentType: TsePaymentType,
                    amount: number,
                    addressData?: {
                        fullName?: string,
                        street?: string,
                        houseNumber?: string,
                        postCode?: string,
                        city?: string,
                        country?: Country,
                    }): Observable<BcmReceipt> {

        const date = new Date();

        const person = invoice.person;
        const company = invoice.company;

        if (person && !!addressData) {
            person.street = !!addressData.street && !!addressData.houseNumber
                ? `${addressData.street} ${addressData.houseNumber}`
                : person.street;
            person.postCode = addressData.postCode || person.postCode;
            person.city = addressData.city || person.city;
            person.country = addressData.country || person.country;
        }

        if (company && !!addressData) {
            company.street = !!addressData.street && !!addressData.houseNumber
                ? `${addressData.street} ${addressData.houseNumber}`
                : company.street;
            company.postCode = addressData.postCode || company.postCode;
            company.city = addressData.city || company.city;
            company.country = addressData.country || company.country;
        }

        const document: TseDocument = {
            documentGuid: uuidv4(),
            documentId: 'receiptIdentifier',
            documentNumber: 'receiptIdentifier',
            automaticVatCalculation: TseAutomaticVatCalculation.NO_CALCULATION,
            fiscalDocumentNumber: 1,
            FiscalDocumentStartTime: date.getTime(),
            fiscalDocumentRevision: 1,
            isTraining: false,
            positionCount: 1,
            uniqueClientId: cashRegister.clientId,
            createDate: date.toUTCString(),
            bookDate: date.toUTCString(),
            documentType: TseDocumentType.PAY_IN,
            user: {
                id: this._userId,
                caption: this._userFullName,
                firstName: this._userFirstName,
                lastName: this._userLastName
            },
            partner: (Math.abs(amount) >= 250 && (person?.id || company?.id)) ?
                {
                    id: `${person?.id || company?.id}-${person?.fullName || company?.fullName || addressData?.fullName}`,
                    caption: person?.fullName || company?.fullName || addressData?.fullName,
                    street: person?.streetWithoutNumber || company?.streetWithoutNumber || addressData?.street,
                    streetNumber: person?.streetNumber || company?.streetNumber || addressData?.houseNumber,
                    city: person?.city || company?.city || addressData?.city,
                    postalCode: person?.postCode || company?.postCode || addressData?.postCode,
                    countryCode: countries.getAlpha3Code(
                        person?.country?.de ||
                        company?.country?.de ||
                        addressData?.country?.de ||
                        'Deutschland', 'de'
                    ),
                    identificationType: TsePartnerIdentificationType.OTHER,
                    partnerType: TsePartnerType.CUSTOMER,
                    partnerClassification: 'Customer'
                } :
                null,
            positions: [
                {
                    caption: `Einzahlung ${invoice.invoiceNumber}`,
                    itemCaption: `Einzahlung ${invoice.invoiceNumber}`,
                    quantity: 1,
                    grossValue: amount,
                    baseGrossValue: amount,
                    businessTransactionType: TseBusinessTransactionType.PAY_IN,
                    itemId: 'UUID_' + uuidv4(),
                    netValue: amount,
                    taxValue: 0,
                    vatIdentification: 5,
                    type: TseDocumentPositionType.BOOKING
                }
            ],
            payments: [
                {
                    amount: amount,
                    currencyIsoCode: 'EUR',
                    caption: 'PAY_IN',
                    uniqueReadablePaymentIdentifier: `PAY_IN-${invoice.invoiceNumber}-${new Date().getTime()}-${cashRegister.clientId}`,
                    foreignAmount: 0.0,
                    foreignAmountExchangeRate: 0.0,
                    paymentType
                }
            ]
        };

        const address = (person || company)?.getAddressString('\n', true, true, true)
            || getAddressString(addressData)
            || invoice?.address;

        const pdfData: BcmFinancialRecordPdfData = {
            address: address,
            bcmSettings: null,
            date: null,
            debtorNumber: person?.identNumber || company?.identNumber,
            iban: person?.IBAN || company?.IBAN,
            invoiceLogo: null,
            // invoiceNumber: receiptNumber,
            paymentType: BcmPaymentTypeFromString[paymentType],
            positions: [
                // @ts-ignore
                {
                    title: `Einzahlung ${invoice.invoiceNumber}`,
                    price: amount,
                    invoice: invoice,
                    quantity: 1,
                }
            ],
            priceData: {
                totalTaxes: [],
                totalTax: 0,
                totalGrossPrice: amount,
                totalNetPrice: amount,
                totalTaxFreeProductsPrice: amount
            },
            tenant: null,
            tenantRelation: null,
        };

        return this._tseApiService.createDocument(
            cashRegister,
            document,
            [],
            amount,
            0,
            pdfData,
            paymentType,
            person,
            company,
        );
    }


    // incrementReceiptReprintCounter(cashRegister: BcmCashRegister, receipt: BcmReceipt): Observable<BcmReceipt> {
    //     const diff = Math.abs((receipt.insertedOn || new Date()).getTime() - new Date().getTime());
    //     const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    //
    //     const daysBack = diffDays > 0 ? diffDays : -1;
    //
    //     const user = {
    //         id: this._userId,
    //         caption: this._userFullName,
    //         firstName: this._userFirstName,
    //         lastName: this._userLastName
    //     };
    //
    //     return this._cashRegisterApi.incrementReprintCounter(cashRegister, receipt.id);
    //
    //     // tse api call not working at the moment
    //     // return this._transactionsApi.incrementReprintCount(cashRegister.clientId, receipt.tseDocument.documentGuid, user, daysBack)
    //     //     .pipe(switchMap(() => this._cashRegisterApi.incrementReprintCounter(cashRegister, receipt.id)));
    // }

    private _getGrossValue(position: InvoicePosition): number {
        if (!StaticGlobals.netPrices) {
            return roundCurrency(position.price * position.quantity);
        } else {
            return this._getNetValue(position) + this._getTaxValue(position);
        }
    }

    private _getNetValue(position: InvoicePosition): number {
        const taxRateValue = getTaxRateValue(position);
        if (StaticGlobals.netPrices) {
            return roundCurrency(position.price * position.quantity);
        } else {
            return roundCurrency(position.price * position.quantity / ((taxRateValue / 100) + 1));
        }
    }

    private _getTaxValue(position: InvoicePosition): number {
        const taxRateValue = getTaxRateValue(position);
        if (StaticGlobals.netPrices) {
            return roundCurrency(position.price * position.quantity * (taxRateValue / 100));
        } else {
            return roundCurrency((position.price * position.quantity) - this._getNetValue(position));
        }
    }

    public getTotal(positions: InvoicePosition[]): number {
        const total = positions.map(p => this._getGrossValue(p)).reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        return roundCurrency(total);
    }

}
