import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
    addDays,
    endOfMonth,
    endOfToday,
    endOfTomorrow,
    endOfWeek,
    startOfTomorrow,
    subDays,
} from '@core/date.facade';
import { SharedLayerService } from '@modules/bcm/berths/berths-map/_shared/shared-layer.service';
import { DateRangeType } from '@modules/bcm/berths/berths-map/berth-map-action-bar/berth-map-action-bar.component';
import { HttpParams } from '@angular/common/http';
import { BerthsFacadeOld } from '@bcmServices/berths/berths-facade-old.service';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { toSqlDateTime } from '@core/functions/to-date-string';
import { parseInt } from 'lodash';
import { Boat } from '@shared/models/boat';
import { BehaviorSubject, take } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { deepEqual } from '@shared/functions/deep-equal';
import { isValidDate } from '@core/functions/is-valid-date';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { BcmSettingsSectionName, DefaultBerthReservationTimeUnit } from '@shared/models/bcm-settings';
import { differenceInCalendarDays, isSameDay } from 'date-fns';

export type LastFilter = {
    timestamp: number,
    start: Date,
    end: Date,
    length: number,
    width: number,
    maxDraft: number,
    boat?: Boat,
};

export interface DateRange {
    start: Date | null;
    end: Date | null;
}

type DateRangeUpdateType = 'date-range' | 'time-slider' | 'date-select';

@Injectable({providedIn: 'root'})
export class BerthsMapFilterService {


    private centralDateRangeSubject = new BehaviorSubject<DateRange>(null);

    public centralDateRange = this.centralDateRangeSubject
        .pipe(
            debounceTime(50),
            filter(({start, end}) => isValidDate(start) && (isValidDate(end) || !end)),
            distinctUntilChanged((a, b) => deepEqual(a, b)),
        );

    currentDateSelectType = DateRangeType.Today;

    defaultBerthReservationTimeUnit: DefaultBerthReservationTimeUnit;

    private _advancedBerthFiltersOpen: boolean;

    public get advancedBerthFiltersOpen(): boolean {
        return this._advancedBerthFiltersOpen;
    }

    private _berthFormGroup = new FormGroup<{
        length: AbstractControl<number>,
        width: AbstractControl<number>,
        maxDraft: AbstractControl<number>,
        boatType: AbstractControl<string>,
        boat: AbstractControl<Boat>,
    }>({
        length: new FormControl(null,
            [
                U2bNumericValidators.numberFormat(),
                U2bNumericValidators.numberMin(0, 'Keine negativen Werte erlaubt'),
            ]
        ),
        width: new FormControl(null,
            [
                U2bNumericValidators.numberFormat(),
                U2bNumericValidators.numberMin(0, 'Keine negativen Werte erlaubt'),
            ]
        ),
        maxDraft: new FormControl(null,
            [
                U2bNumericValidators.numberFormat(),
                U2bNumericValidators.numberMin(0, 'Keine negativen Werte erlaubt'),
            ]
        ),
        boatType: new FormControl(null),
        boat: new FormControl(null),
    });

    public get berthFormGroup(): FormGroup {
        return this._berthFormGroup;
    }

    private _lastBerthFilters: LastFilter[] = [];

    get lastBerthFilters(): LastFilter[] {
        return this._lastBerthFilters;
    }

    get lastBerthFilter(): LastFilter {
        return this._lastBerthFilters.length ? this._lastBerthFilters[0] : {} as LastFilter;
    }

    constructor(private _berthFacade: BerthsFacadeOld,
                private _bcmSettingsFacade: BcmSettingsFacade) {
        this.initSubscriptions();

        this._bcmSettingsFacade.settings$
            .pipe(
                filter(settings => !!settings),
                take(1)
            )
            .subscribe(settings => {

                this.defaultBerthReservationTimeUnit = settings[BcmSettingsSectionName.DefaultBerthReservationTimeUnit];

                const startDate = new Date();
                const endDate = new Date();

                if (this.defaultBerthReservationTimeUnit.dailyGuestFromHours &&
                    this.defaultBerthReservationTimeUnit.dailyGuestFromMinutes &&
                    this.defaultBerthReservationTimeUnit.dailyGuestToHours &&
                    this.defaultBerthReservationTimeUnit.dailyGuestToMinutes) {
                    startDate.setHours(this.defaultBerthReservationTimeUnit.dailyGuestFromHours, this.defaultBerthReservationTimeUnit.dailyGuestFromMinutes, 0, 0);
                    endDate.setHours(this.defaultBerthReservationTimeUnit.dailyGuestToHours, this.defaultBerthReservationTimeUnit.dailyGuestToMinutes, 0, 0);
                } else {
                    startDate.setHours(0, 0, 0, 0);
                    endDate.setHours(23, 59, 59, 999);
                }

                this.centralDateRangeSubject.next({start: startDate, end: endDate});

            });

    }

    setDateRange({start, end}: DateRange, dateRangeUpdateType: DateRangeUpdateType = 'date-select'): void {

        start = isValidDate(start) ? start : null;
        end = isValidDate(end) ? end : null;

        if (!start) {
            return;
        }

        if (dateRangeUpdateType === 'date-range') {

            const currentStart = this.centralDateRangeSubject.getValue()?.start;
            const currentEnd = this.centralDateRangeSubject.getValue()?.end;

            if (!end || !currentEnd) {
                return;
            }

            start.setHours(currentStart.getHours(), currentStart.getMinutes(), 0, 0);
            end.setHours(currentEnd.getHours(), currentEnd.getMinutes(), 0, 0);

        } else if (dateRangeUpdateType === 'time-slider') {

            // nothing to do here, we take the time from the slider

        } else {

            // TODO check if dates differ

            const currentStart = this.getDateRange()?.start;
            const currentEnd = this.getDateRange()?.end;

            if (isSameDay(currentStart, start) && isSameDay(currentEnd, end)) {
                return;
            }

            const differenceInDays = differenceInCalendarDays(end, start);

            const {
                dailyGuestFromHours,
                dailyGuestFromMinutes,
                dailyGuestToHours,
                dailyGuestToMinutes,
                overnightGuestFromHours,
                overnightGuestFromMinutes,
                overnightGuestToHours,
                overnightGuestToMinutes
            } = this.defaultBerthReservationTimeUnit;

            if (!end) {
                start.setHours(overnightGuestFromHours, overnightGuestFromMinutes, 0, 0);
            } else if (differenceInDays === 0) {
                start.setHours(dailyGuestFromHours, dailyGuestFromMinutes, 0, 0);
                end = new Date(start);
                end.setHours(dailyGuestToHours, dailyGuestToMinutes, 0, 0);
            } else if (differenceInDays > 0) {
                start.setHours(overnightGuestFromHours, overnightGuestFromMinutes, 0, 0);
                end.setHours(overnightGuestToHours, overnightGuestToMinutes, 0, 0);
            }

        }

        this.centralDateRangeSubject.next({start, end: end});
    }

    getDateRange(): DateRange {
        return this.centralDateRangeSubject.getValue();
    }

    isTimeSet(date: Date | null | undefined): boolean {
        if (!date) {
            return false; // Kein Datum vorhanden
        }
        return date.getHours() !== 0 || date.getMinutes() !== 0 || date.getSeconds() !== 0 || date.getMilliseconds() !== 0;
    }

    jumpDays(days: number): void {

        const {start: currStart, end: currEnd} = this.getDateRange();

        let start: Date;
        let end: Date;

        if (days < 0) {
            start = subDays(currStart, Math.abs(days));
            end = subDays(currEnd, Math.abs(days));
        }

        if (days > 0) {
            start = addDays(currStart, days);
            end = addDays(currEnd, days);
        }

        this.setDateRange({start, end});
        this.updateBerthView();
    }

    toggleAdvancedFilters(): void {
        this._advancedBerthFiltersOpen = !this._advancedBerthFiltersOpen;
    }

    public getTimeStringFromSliderValue(value: number): string {

        if (value === 1440) {
            return '23:59';
        }

        const hourStr = this.getHourStringFromSliderValue(value).toString().padStart(2, '0');
        const minuteStr = this.getMinuteStringFromSliderValue(value).toString().padStart(2, '0');

        return `${hourStr}:${minuteStr}`;
    }

    public getHourStringFromSliderValue(value: number): number {
        const hourStr = (value / 60).toString().split('.')[0];
        return Math.min(23, parseInt(hourStr, 10));
    }

    public getMinuteStringFromSliderValue(value: number): number {
        return value === 1440 ? 60 : (value % 60);
    }

    public selectDate(value: DateRangeType): void {
        this.currentDateSelectType = value;
        const now = new Date();
        let start: Date;
        let end: Date;

        switch (value) {
            case DateRangeType.RestOfMonth:
                start = now;
                end = endOfMonth(now);
                break;
            case DateRangeType.RestOfWeek:
                start = now;
                end = endOfWeek(now);
                break;
            case DateRangeType.Today:
                start = now;
                end = endOfToday();
                break;
            case DateRangeType.Tomorrow:
                start = startOfTomorrow();
                end = endOfTomorrow();
                break;
        }

        this.setDateRange({start, end});
        this.updateBerthView();
    }

    updateBerthView(keepBerthFiltersOpen = false): void {
        const {length, width, maxDraft, boatType, boat} = this._berthFormGroup.value;
        const {start, end} = this.getDateRange();

        // newest on start!
        this.lastBerthFilters.unshift({
            boat,
            length,
            width,
            maxDraft,
            start,
            end,
            timestamp: Date.now()
        });

        this._lastBerthFilters = this.lastBerthFilters.slice(0, 25);

        const httpParams = new HttpParams()
            .set('from', toSqlDateTime(start) || '')
            .set('to', toSqlDateTime(end) || '')
            .set('berthLength', !isNaN(length) ? String(length) : '')
            .set('berthWidth', !isNaN(width) ? String(width) : '')
            .set('berthMaxDraft', !isNaN(maxDraft) ? String(maxDraft) : '')
            .set('berthBoatType', boatType || '');

        this._berthFacade
            .loadBerthMapList(httpParams)
            .add(() => {
                this._advancedBerthFiltersOpen = this._advancedBerthFiltersOpen && keepBerthFiltersOpen;
            });
    }

    public reset() {
        this.centralDateRangeSubject.next({start: new Date(), end: endOfToday()});
    }

    private initSubscriptions(): void {
        SharedLayerService
            .mapObjectRawList$
            .subscribe(items => {
                if (items?.length) {
                    this.updateBerthView();
                }
            });
    }
}
